import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {IoIosAdd} from 'react-icons/io';
import {Entry} from 'src/components/entries/entry';
import {
  GlobalStateContext,
} from 'src/context/globalContextProvider';
import styles from './project.module.scss';

export const Project = (props) => {
  const state = useContext(GlobalStateContext);
  // TODO: eliminate props.projectMap after it isn't required for the demo
  const projectMap = Object.keys(state.projectMap).length > 0
    ? state.projectMap : props.projectMap;

  return (
    <div className={styles.project}>
      <div className={styles.titleWrapper}>
        {!props.projectView &&
          <div className={styles.title}>
            {projectMap[props.projectId].name}
          </div>
        }
      </div>
      <div className={styles.entries}>
        {Object.keys(props.entries).map((key) => (
          <Entry
            key={`entry-${key}`}
            entryPk={props.entries[key].pk}
            content={props.entries[key].content}
            projectId={props.entries[key].project}
            focused={props.entries[key].focused}
            actionable={props.entries[key].actionable}
            demoMode={props.demoMode}
            // data={props.entries[key]}
            onDeleted={props.onEntryDeleted}
            projectView={props.projectView}
          />
        ))}
        <div className={styles.addEntry}>
          <button onClick={props.onAddEntry}>
            <IoIosAdd />
          </button>
        </div>
      </div>
    </div>
  );
};

Project.propTypes = {
  entries: PropTypes.object.isRequired,
};
