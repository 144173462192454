import {graphql} from 'gatsby';
import {Link} from 'gatsby';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {IoIosArrowRoundDown} from 'react-icons/io';
import {Project} from 'src/components/entries/project';
import SEO from 'src/components/seo';
import {BrowserMockup} from 'src/components/ui/browserMockup';
import {Button} from 'src/components/ui/button';
import LogoWithText from 'src/images/logo_light_with_text.svg';
import {trackPageview} from 'src/shared/utilities/analytics';
import {browser} from 'src/shared/utilities/environment';
import {getUser} from 'src/shared/utilities/session';
import styles from './index.module.scss';

const today = moment().format('ddd M/D').toUpperCase();
const yesterday = moment().subtract(1, 'day').format('ddd M/D').toUpperCase();

const defaultEntries = {
  '1': {
    pk: '1',
    projectId: today,
    content: 'Finished blog post',
  },
  '2': {
    pk: '2',
    projectId: today,
    content: 'Signed up for Hacker Paper! 😉',
  },
  '3': {
    pk: '3',
    projectId: today,
    content: 'Learn how to use to-do entries #todo',
    actionable: true,
  },
  '4': {
    pk: '4',
    projectId: yesterday,
    content: 'Write blog post about 100 Days Of Code #todo',
    actionable: true,
    completed_at: true,
  },
  '5': {
    pk: '5',
    projectId: yesterday,
    content: 'Respond to latest customer support emails #todo',
    actionable: true,
    completed_at: true,
  },
  '6': {
    pk: '6',
    projectId: yesterday,
    content: 'Find a good journal app #todo',
    actionable: true,
    completed_at: true,
  },
};

const projectMap = {
  [today]: {name: today},
  [yesterday]: {name: yesterday},
};

export default (props) => {
  if (!browser()) {
    return null;
  }

  if (getUser()) {
    window.location = '/journal';
    return null;
  }

  useEffect(() => {
    trackPageview();
  }, []);

  const [entries, setEntries] = useState(defaultEntries);

  const handleAddEntry = (projectPk) => {
    const newPk = (Object.keys(entries).length + 1000).toString();
    setEntries({
      ...entries,
      [newPk]: {
        pk: newPk,
        content: '',
        projectId: projectPk,
        focused: true,
      },
    });
  };

  const handleEntryDeleted = (entryPk) => {
    const newEntries = {...entries};
    delete newEntries[entryPk];
    setEntries(newEntries);
  };

  const todayEntries = {};
  const yesterdayEntries = {};

  Object.keys(entries).forEach((entryPk) => {
    if (entries[entryPk].projectId === today) {
      todayEntries[entryPk] = entries[entryPk];
    } else {
      yesterdayEntries[entryPk] = entries[entryPk];
    }
  });

  return (
    <div className={styles.pageWrapper}>
      <SEO/>
      <div className={styles.nav}>
        <div className={styles.logoWrapper}>
          <Link to="/">
            <img src={LogoWithText} />
          </Link>
        </div>
        <div className={styles.authLink}>
          <Link to="/login">
            Log In
          </Link>
        </div>
        <div className={styles.authLink}>
          <Link to="/signup">
            Sign Up
          </Link>
        </div>
      </div>
      <h3 className={styles.title}>
        The journal trusted by hackers, hustlers, and people who make stuff.
      </h3>
      <div className={styles.demo}>
        <div className={styles.try}>
          <div>
            Try it
          </div>
          <IoIosArrowRoundDown />
        </div>
        <BrowserMockup>
          <>
            <div className={styles.browserContent}>
              <Project
                projectId={today}
                projectMap={projectMap}
                demoMode={true}
                entries={todayEntries}
                onAddEntry={() => handleAddEntry(today)}
                onEntryDeleted={handleEntryDeleted}
              />
            </div>
            <div className={styles.browserContent}>
              <Project
                projectId={yesterday}
                projectMap={projectMap}
                demoMode={true}
                entries={yesterdayEntries}
                onAddEntry={() => handleAddEntry(yesterday)}
                onEntryDeleted={handleEntryDeleted}
              />
            </div>
          </>
        </BrowserMockup>
      </div>
      <div className={styles.values}>
        <ValueItem
          title="A process, not just an app"
          description="Strategies and habits baked right into the app."
          fluid={props.data.planIcon.childImageSharp.fluid}
        />
        <ValueItem
          title="Never forget"
          description="Mark journal entries as to-do items and review them later."
          fluid={props.data.todoIcon.childImageSharp.fluid}
        />
        <ValueItem
          title="Disconnect when you want"
          description="Leave the project in your journal, not in the back of your mind."
          fluid={props.data.planIcon.childImageSharp.fluid}
        />
        <ValueItem
          title="More than just work"
          description="Use your journal to record a daily grateful log, reflect on relationships, or just blow off some steam. There's more to life than work, ya know 😉."
          fluid={props.data.planIcon.childImageSharp.fluid}
        />
        {/*
        <Card className={styles.valueCard}>
          <div className={styles.valueTitle}>
            How it's made
          </div>
          <div className={styles.valueDescription}>
            The creator of Hacker Paper uses Hacker Paper to make Hacker Paper...say that 10 times fast. If you're curious, check out the live stream recordings to see how the proverbial sausages are made: Day 1, Day 2, more...
          </div>
        </Card>
        */}
      </div>
      <div className={styles.cta}>
        <div>
          Ready to try a new approach to getting things done?
        </div>
        <Button
          primary
          large
          className={styles.cta}
          to="/signup"
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};

const ValueItem = (props) => {
  return (
    <div className={styles.value}>
      {/*
      <div>
        <Img
          className={styles.valueImage}
          fluid={props.fluid}
        />
      </div>
      */}
      <div>
        <div className={styles.valueTitle}>
          {props.title}
        </div>
        <div className={styles.valueDescription}>
          {props.description}
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    laptopScene: file(relativePath: { eq: "laptop_scene.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    planIcon: file(relativePath: { eq: "plan_icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    todoIcon: file(relativePath: { eq: "todo_icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
