import classNames from 'classnames';
import React from 'react';
import styles from './browserMockup.module.scss';

export const BrowserMockup = (props) => {
  const classes = classNames(styles.browser, props.className);

  return (
    <div className={classes}>
      <div className={styles.appBar}>
        <div className={styles.circles}>
          <div className={styles.red}></div>
          <div className={styles.yellow}></div>
          <div className={styles.green}></div>
        </div>
      </div>
      {props.children}
    </div>
  );
};
